/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';

import twoPanelTemplate from '../common/two-panel.html';
import editTemplate from './ad-unit-edit.html';
import analysisTemplate from './ad-unit-analysis.html';
import copyTemplate from '../common/copy-actions.html';
import listTemplate from './ad-unit-list.html';

import standardModule from '../common/standard-module';
import adUnitController from './ad-unit-controller';
import adUnitAnalysisController from './ad-unit-analysis-controller';
import adUnitActionsController from './ad-unit-actions-controller';
import dimensionsComponent from './dimension-output-component';
import {adTagLinker} from "./ad-tag-linker";
import {adUnitBulkAction} from "./ad-unit-commons";
import {LocalNetworkInfo} from "../../components/session/local-network-info";
import _ from "lodash";
import {getCountryName} from "../common/directives/country-select/countries";

const MODULE_NAME = 'ad-unit-module';

angular.module(MODULE_NAME, [standardModule, adUnitController, adUnitAnalysisController, adUnitActionsController, dimensionsComponent])

  .config(function($stateProvider, adnRouterHelperProvider) {
    let rh = adnRouterHelperProvider.$get().createRouterHelper('ad-units.ad-unit', 'AdUnit', 'app.inv', 'app', listTemplate);
    $stateProvider.state(rh.listPath(), rh.list({
      resolve: {
        extension: /*@ngInject*/ function(LocalNetworkProfile, localUserProfile) {
          return {
            func: function(ctrl, afterFill) {
              if (afterFill) {
                _.forEach(ctrl.items, function(item) {
                  item.countryExpanded = getCountryName(item.country);
                });
                return;
              }
              ctrl.auFields = ['name', {param: '', label: 'dimensions'}, {label: 'site', param: 'Site'}];
              if (LocalNetworkInfo.obtain().getInfo("siteCountries").length > 1) {
                ctrl.auFields.push({label: "country", param: ''});
              }
              ctrl.auFields.push({label: 'status', param: 'requestedInLastDay'});
              ctrl.auFields.push({label: 'tagId'});

              const isSpecialUser = localUserProfile.get("username") === "adnuntius@adnuntius.com" || localUserProfile.get("username") === "broker1@adnuntius.com";
              if (LocalNetworkProfile.isPureMarketplacePlatform() && isSpecialUser) {
                ctrl.auFields.push({'label': 'isFullPage', 'param': 'fullPage'});
              }
            }
          };
        },
        bulk: function() {
          return {
            adTagGenerator: adTagLinker,
            bulkAction: adUnitBulkAction
          };
        }
      }
    }))
      .state(rh.newItemPath(), rh.newItem())
      .state(rh.itemPath(), rh.modelCommsItem({
        resolve: {
          tags: /*@ngInject*/ function($q, $stateParams, AdUnitTag) {
            if ($stateParams.isNew) {
              return $q.when({});
            }
            return AdUnitTag.get({id: $stateParams.id}).$promise;
          }
        },
        views: {
          'content@app': {
            controller: null,
            template: twoPanelTemplate
          },
          'context@app': {
            template: copyTemplate,
            controller: 'AdUnitActionsCtrl',
            controllerAs: 'ctrl'
          },
          'leftPanel@app.ad-units.ad-unit': {
            template: editTemplate,
            controller: 'AdUnitCtrl',
            controllerAs: 'resource'
          },
          'rightPanel@app.ad-units.ad-unit': {
            template: analysisTemplate,
            controller: 'AdUnitAnalysisCtrl',
            controllerAs: 'ctrl'
          }
        }
      }, 'AdUnit', ['EarningsAccount', 'Site']));
  });

export default MODULE_NAME;